<template>
  <v-container>
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Statystyki zawodów</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        Statystyki zawodów nie są jeszcze dostępne.
        <!-- Select competition -->
        <v-autocomplete v-if="false" label="Zawody" v-model="competition" :items="competitions"
                        item-text="name" item-value="id" hide-details @change="loadStats()"/>

        <!-- Show stats -->
        <v-card v-if="stats != null" outlined class="mt-4">
          <v-card-title>{{ stats.name }}</v-card-title>
          <v-card-text v-if="stats != null">
            <!-- TODO show stats -->
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AdminStatsCompetitor',
  data: () => ({
    competitions: [], competition: null, stats: null
  }),
  created() {
    this.$http.post('/api/admin/live/competitions').then((res) => {
      this.competitions = res.data;
    }).catch((e) => {
      console.log(e);
    });
  },
  methods: {
    loadStats() {
      this.$http.post('/api/admin/stats/competition', {
        competitionId: this.competition
      }).then((res) => {
        this.stats = res.data;
      }).catch((e) => {
        console.log(e);
      });
    }
  }
};
</script>
